import React, { useState, useEffect, useContext } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { MdCall, MdEmail } from "react-icons/md";
import axios from "axios";
import { LoginManageContext } from "./../App";
import { NavLink } from "react-router-dom";
import { useTeamMemberContext } from "../TeamMembersContext";
const Team = () => {
  const [teamMember, setTeamMember] = useState({});
  // const uId = localStorage.getItem("userId");
  const { loginData } = useContext(LoginManageContext);
  const { setTeamMemberId } = useTeamMemberContext();
  //var userData = loginData.userData;
  var stateInfo = loginData;

  /*
   * fetch team info on load
   */
  const getAllTeamMember = () => {
    const searchType = stateInfo.userData.team_name;
    axios
      .post("/getTeamDetailsApi.php", {
        teamId: stateInfo.userData.team_id,
        searchType: searchType,
      })
      .then(function (response) {
        const res = response.data;
        // console.log(res);
        if (res.statusCode === 200) {
          setTeamMember(res.result);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  /*
   * filter team
   */
  const filterTeam = (e) => {
    const searchType = e.target.value;
    axios
      .post("/getTeamDetailsApi.php", {
        teamId: stateInfo.userData.team_id,
        searchType: searchType,
      })
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          setTeamMember(res.result);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllTeamMember();
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="teams" className="main-view">
            <div className="view-head">
              <h6> Team Members </h6>
              {/* <form method="get" className="select-team">
                <div className="task-filter">
                  <select
                    name="teamId"
                    onChange={filterTeam}
                    class="form-control filter"
                  >
                    <option value="0"> -- Select Team -- </option>
                    <option value="All"> All </option>
                    <option value="Ctax"> CTAX</option>
                    <option value="CLG"> CLG </option>
                  </select>
                </div>
              </form> */}
            </div>
            <div class="team-wrapper">
              <div class="team-grid">
                <table>
                  <thead>
                    <tr>
                      <th className="name">Name </th>
                      <th className="skills"> Skills </th>
                      <th className="contact"> Contact </th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamMember.length > 0
                      ? teamMember.map((row, index) => (
                        <tr>
                          <td>
                            <span className="team-mate">
                              <img
                                class="empTeam"
                                // src={
                                //   row.photo !== "" && row.photo !== null
                                //     ? "https://crm.nablasol.net/fromAPI/" +
                                //       row.photo
                                //     : row.gender === "Male"
                                //     ? "https://crm.nablasol.net/fromAPI/male.png"
                                //     : "https://crm.nablasol.net/fromAPI/female.png"
                                // }
                                src={
                                  row.photo !== "" && row.photo !== null
                                    ? "https://crm.nablasol.net/fromAPI/" +
                                    row.photo
                                    : row.photo_url != "" ? row.photo_url : row.gender === "Male" ? "https://crm.nablasol.net/fromAPI/male.png" : "https://crm.nablasol.net/fromAPI/female.png"
                                }
                                alt={row.first_name.charAt(0).toUpperCase()}
                              />
                              <div className="emp-details">

                                <NavLink  style={{pointerEvents: loginData.userData.id !== '9088b19c-bed5-e042-667e-60c730f49b3e' && row.userId === '9088b19c-bed5-e042-667e-60c730f49b3e'  ? 'none' : ''}}
                                  to={`/team-members/${row.userName}`}
                                  state={{userInfo: row.first_name+" "+row.last_name}}
                                  activeClassName="hello"
                                  onClick={() => setTeamMemberId(row.userId)}
                                >

                                  <a className="team-emp-name">
                                    {row.first_name.charAt(0).toUpperCase() +
                                      row.first_name
                                        .slice(1)
                                        .toLowerCase()}{" "}
                                    {row.last_name.charAt(0).toUpperCase() +
                                      row.last_name.slice(1).toLowerCase()}{" "}
                                  </a>

                                </NavLink>
                                <span class="team-emp-designation">
                                  {row.designation.replace(/&amp;/gi, '&')}
                                </span>
                              </div>
                            </span>
                          </td>
                          <td className="skills-column">
                            <span className="skills">
                              <div>
                                <span className="skill-type">
                                  Proficient :
                                </span>
                                <span className="skill-value">
                                  {row.tech_skills_emp_c != "" &&
                                    row.tech_skills_emp_c != null
                                    ? row.tech_skills_emp_c
                                      .replace(/[^a-zA-Z0-9]/g, " ")
                                      .trim()
                                      .replace(/\s+/g, ", ")
                                    : ""}
                                </span>
                              </div>
                              <div>
                                <span className="skill-type">Average :</span>
                                <span className="skill-value">
                                  {row.tech_avg_skills_c != "" &&
                                    row.tech_avg_skills_c != null
                                    ? row.tech_avg_skills_c
                                      .replace(/[^a-zA-Z0-9]/g, " ")
                                      .trim()
                                      .replace(/\s+/g, ", ")
                                    : ""}
                                </span>
                              </div>
                              <div>
                                <span className="skill-type">Beginner :</span>
                                <span className="skill-value">
                                  {row.tech_beginner_skills_c != "" &&
                                    row.tech_beginner_skills_c != null
                                    ? row.tech_beginner_skills_c
                                      .replace(/[^a-zA-Z0-9]/g, " ")
                                      .trim()
                                      .replace(/\s+/g, ", ")
                                    : ""}{" "}
                                </span>
                              </div>
                            </span>
                          </td>
                          <td>
                            <div className="contact-mate">
                              <a
                                href={`tel:${row.phone}`}
                                className="call"
                                title={row.phone}
                              >
                                <MdCall />
                              </a>
                              <a
                                href={`mailto:${row.companyemail}`}
                                className="email"
                                title={row.companyemail}
                              >
                                <MdEmail />
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
