import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const QuoteCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = [
    {
      text: "Success is not final, failure is not fatal: It is the courage to continue that counts.",
      author: "- Winston Churchill",
    },
    {
      text: "Great things in business are never done by one person; they’re done by a team of people.",
      author: "- Steve Jobs",
    },
    {
      text: "Coming together is a beginning. Keeping together is progress. Working together is success.",
      author: "- Henry Ford",
    },
    {
      text: "Alone we can do so little; together we can do so much.",
      author: "- Helen Keller",
    },
    {
      text: "Some people want it to happen, some wish it would happen, others make it happen",
      author: "- Michael Jordan",
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="slider-wrapper" key={index}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="28"
              viewBox="0 0 38 28"
              fill="none"
            >
              <path
                d="M13.3333 0.666672L8 11.3333H16V27.3333H0V11.3333L5.33333 0.666672H13.3333ZM34.6667 0.666672L29.3333 11.3333H37.3333V27.3333H21.3333V11.3333L26.6667 0.666672H34.6667Z"
                fill="#10567E"
              />
            </svg>
            <h3 className="slide-text">{slide.text}</h3>
            <h3 style={{ textAlign: "right" }}>{slide.author}</h3>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default QuoteCarousel;
