import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { NavLink, useLocation, useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import FormControl from "@mui/material/FormControl";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsBackspaceFill } from "react-icons/bs";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const EmployeeUpcomingTrainings = () => {
  const [mainloader, setMainLoader] = useState(true);
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  // const loginUid = localStorage.getItem("userId");
  // let i = 0;

  const columns = [
    "Name of Training",
    {
      name: "Training URL",
      options: {
        setCellProps: () => ({
          style: {
            minWidth: "400px",
            maxWidth: "400px",
            overflowWrap: "break-word",
          },
        }),
      },
    },
    "Start Date",
    "End Date",
    "Training Type",
  ];

  const options = {
    sortOrder: {
      name: "name",
      direction: "asc",
    },

    search: searchBtn,
    download: downloadBtn,
    downloadOptions: {
      filename: "UpcomingTrainingsEmployee.csv",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
  };

  const location = useLocation();
  const { userId } = useParams();
  const { userRole } = location.state;
  const [data, setData] = useState([]);
  const getUpcomingTrainingList = () => {
    setMainLoader(true);
    axios
      .post("/getTrainingInfo.php", {
        userName: userId,
        userRole: userRole,
      })
      .then((res) => {
        const response = res.data;
        // console.log(response);
        let my_array = [];
        response.forEach((obj) => {
          let inside_array = [];
          Object.entries(obj).forEach(([key, value]) => {
            inside_array.push(`${value}`);
          });
          my_array.push(inside_array);
        });
        // console.log(my_array);
        setData(my_array);
        setMainLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUpcomingTrainingList();
  }, []);
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="reports" className="main-view">
            <div className="view-head">
              <h6> Upcoming Trainings Of Employee </h6>
              <NavLink
                to="/upcoming-trainings-employee"
                // activeClassName="hello"
              >
                {" "}
                <span className="view_back_btn">
                  Back <BsBackspaceFill />
                </span>
              </NavLink>
            </div>

            <div className="view-grid grid-dashlet">
              <div class="reports-wrapper">
                <h3 className="client_view_heading">
                  {location.state?.userInfo}
                </h3>
                {mainloader === true ? (
                  <div className="loader">
                    <img src="/img/hrm-loader.svg" alt="loader" />
                  </div>
                ) : (
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={createTheme()}>
                      <FormControl />
                      <MUIDataTable
                        title={
                          "Upcoming Trainings Of Employee - " +
                          location.state?.userInfo +
                          " - " +
                          userRole
                        }
                        data={data}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeUpcomingTrainings;
