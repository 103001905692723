import React, { useState, useContext, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

function LeaveAcc(props) {
  // const [isActive, setIsActive] = useState(false);
  const isActive = props.isOpen === props.accName;
  // var i = 0;
  return (
    <div className={`leave-acc grid-dashlet ${isActive ? "active" : ""}`}>
      <div
        className="leave-acc-head"
        onClick={() => props.setIsOpen(isActive ? "" : props.accName)}
      >
        <h5> {props.accName} </h5>
        <span className="toggle-icon">
          {isActive ? <TiArrowSortedDown /> : <TiArrowSortedUp />}
        </span>
      </div>
      {isActive && <div className="leave-acc-body">{props.children}</div>}
    </div>
  );
}

function NoticeBoard(props) {
  const renderHTML = (string) =>
    React.createElement("div", { dangerouslySetInnerHTML: { __html: string } });

  return (
    <div>
      {/* {props.leave.length > 0
        ? props.leave[0].leavePolicy.map((row, index) => (
          <>
            <h6> {row.name} </h6>
            <div id={"policy_desc" + index}>
              {renderHTML(row.leave_policy_description)};
            </div>
          </>
        ))
        : ""} */}
      <ul>
        <li>Leave Calendar is from January to December.</li>
        <li>The Due Date for Salary payment is the 7th of every month.</li>
        <li>
          Leave should be applied in advance at least before 7 to 15 Days (not
          applicable for exceptional circumstances).{" "}
        </li>
        <li>
          Leaves can be Unapproved for any reason. Approved leaves can also be
          revoked if circumstances change.
        </li>
        <li>
          Any leave availed without permission/intimation will be treated as
          absenteeism (hence no salary is payable for this period) and can lead
          to disciplinary action.
        </li>
        <li>
          If your leave is for 5 or more continuous calendar days, all weekends
          and other holidays during that period will be counted towards your
          leave count.
          <br />
          <b>
            {" "}
            {"{"}For Instance- If you requested a leave span from Friday to
            Tuesday or encompass multiple consecutive days, then the weekend
            will be clubbed together.
            <br />
            OR
            <br />
            If your planned leave coincides with any public holiday, this policy
            will remain applicable.{"}"}
          </b>
        </li>
        <li>
          If you are not present for at-least 3 days in a week, the weekend for
          that week will be considered a leave too.
          <br />
          <b>
            {"{"}For Instance- If you take a Tuesday, Thursday, and Friday off
            within a single week, these days will be consolidated with the
            upcoming weekend.{"}"}
          </b>
        </li>
        <li>
          If you consume extra leaves in a year it can be adjusted to the next
          year with a maximum of 5 count. This is discretionary for special
          cases on manager approval and only applicable for employees who have
          been with the company for over a year.
        </li>
        <li>
          The insurance policies will be under Care Health now. You can Install
          the <b>Care Health Customer Care App</b> from your Google Play Store
          or Apple Store.
        </li>
      </ul>

      <h6> Quarterly Self-Review </h6>
      <ul>
        <li>
          You are supposed to submit a self-review of what you accomplished
          every quarter (Jan-Mar, Apr-June, July-Sep, Oct-Dec).
        </li>
        <li>The review should include the following:</li>
        <ul>
          <li>Projects/Tasks Completed</li>
          <li>Special Accomplishments/Learnings</li>
        </ul>
      </ul>
    </div>
  );
}

const SecurityGuidelines = () => {
  return (
    <div>
      <h6>Data Security Controls </h6>
      <ul>
        <li>
          You are personally accountable for all network and systems access
          under your user ID, so keep your password absolutely secret. Never
          share it with anyone, not even members of your family, friends, or IT
          staff.
        </li>
        <li>
          Corporate laptops are provided for official use by authorized
          employees. Do not loan your laptop or allow it to be used by others
          such as family and friends. Avoid leaving your laptop unattended and
          logged on. Always shut down, log off or activate a password-protected
          screensaver before walking away from the machine.
        </li>
        <li>
          No offline/online gaming or game installation should be done unless
          it's needed for the work being performed.
        </li>
        <li>
          Users cannot install any pirated/cracked software on the company's
          system or use any illegal torrents or transfer software.
        </li>
        <li>
          chrome plugins: install only permitted plugins (need R&D)
          <ul>
            <li>Allowed Plugins are: Passbolt, Grammarly, Loom, Postman</li>
          </ul>
        </li>
        <li>
          Only use encrypted Wifi, open Wifi networks can comprise security and
          data, WPA3 is the newer - and best - encryption available
        </li>
        <li>
          VPN use is a must: VPNs encrypt your internet traffic and disguise
          your online identity. This makes it more difficult for third parties
          to track your activities online and steal data.
        </li>
        <li>
          Be alert for phishing emails. Phishing emails are fraudulent emails
          that appear to be from a legitimate company or organization but are
          actually sent by cybercriminals in an attempt to steal sensitive.
        </li>
      </ul>
      <h6>Data Backups</h6>
      <ul>
        <li>
          You will be personally responsible for storing your data in Google
          drive.{" "}
        </li>
        <li>
          Remember, if the laptop is stolen, lost, or damaged, or if it simply
          malfunctions, it may be impossible to retrieve any of the data from
          the laptop. Saving the data in Google drive will save your work.
        </li>
      </ul>
    </div>
  );
};

function Terms() {
  const [isOpen, setIsOpen] = useState("Terms & Policies");

  return (
    <>
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="leave-policy" className="main-view">
            <div className="view-head">
              <h6>Terms & Policies</h6>
            </div>
            <div className="view-grid-terms view-grid">
              <LeaveAcc
                accName="Security Guidelines"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <SecurityGuidelines />
              </LeaveAcc>
              <LeaveAcc
                accName="Terms & Policies"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              >
                <NoticeBoard />
              </LeaveAcc>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
