import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { getDateFormate } from "./Header";
function DashHoliday(props) {
  return (
    <div>
      <ul className="hday-list">
        {(props.latestHoliday && props.latestHoliday.length > 0) ? (
          props.latestHoliday.map((row, index) => (
            <li key={index}>
              <div className="hday-details">
                <p className="hday-name">{row.name}</p>
              </div>
              <span className="hday-dt">
                {props.getDateFormate(row.holiday_date_c)}
              </span>
            </li>
          ))
        ) : (
          <li>No holidays available</li>
        )}
      </ul>
    </div>
  );
}

function Holiday(props) {
  var [holiday, setHoliday] = useState({});
  const getAllHoliday = () => {
    axios
      .get("/getAllHolidayListApi.php")
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          setHoliday(res.result);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllHoliday();
  }, []);
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="holiday" className="main-view">
            <div className="view-head">
              <h6> Holidays </h6>
            </div>

            <div className="view-grid grid-dashlet">
              <div class="holiday-wrapper">
                {/* <div class="grid-dashlet"> */}
                <table>
                  <thead>
                    <tr>
                      <th className="h_name"> Holiday </th>
                      <th className="h_date"> Date </th>
                      <th className="h_day"> Day </th>
                    </tr>
                  </thead>
                  <tbody>
                    {holiday.length > 0
                      ? holiday.map((row, index) => (
                          <tr>
                            <td>{row.name}</td>
                            <td>
                              {" "}
                              {getDateFormate(row.holiday_date_c, true)}{" "}
                            </td>
                            <td>
                              {" "}
                              {getDateFormate(
                                row.holiday_date_c,
                                false,
                                true
                              )}{" "}
                            </td>
                          </tr>
                        ))
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Holiday;
export { DashHoliday };
