import { createContext, useContext, useState } from "react";

const TeamMembersContext = createContext();

function TeamMembersProvider({ children }) {
  const [teamMemberId, setTeamMemberId] = useState(null);

  return (
    <TeamMembersContext.Provider value={{ teamMemberId, setTeamMemberId }}>
      {children}
    </TeamMembersContext.Provider>
  );
}

const useTeamMemberContext = function () {
  const context = useContext(TeamMembersContext);
  if (context === undefined) {
    throw new Error("Context used outside of the provider");
  }
  return context;
};

export { TeamMembersProvider, useTeamMemberContext };
