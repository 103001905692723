import React, { useState, useEffect, useContext } from "react";
import Greetings from "./Greetings";
import BDay from "./BDay";
import { DashHoliday } from "./Holiday";
import Leave from "./Leave";
import { getDateFormate } from "./Header";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Container, Row, Modal } from "react-bootstrap";
import Tmeet from "./Tmeet";
import Trello from "./Trello";
import TaskMgmt from "./TaskMgmt";
import { Link, useNavigate } from "react-router-dom";
import { RiTimerLine, RiInformationLine } from "react-icons/ri";
import { BsFillCalendarCheckFill } from "react-icons/bs";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import onLeave from "../Images/vaccination.png";
//import moment from "moment";
import { LoginManageContext } from "./../App";
import Calendar from "./Calendar";
import Issue from '../Images/time_management.png';
const DashCards = (props) => {
  return (
    <div className="grid-dashlet" id={`${props.label}`}>
      <div>
        <p className={`${props.label}-head`}>
          {props.name}
          <span>
            {/* <a href={props.link} className="d-inline-block">
              {props.linktext}
            </a> */}
            {props.label === "lvstatus" ? (
              <a onClick={props.modalOpen}>{props.linktext}</a>
            ) : (
              <Link to={props.link} className="d-inline-block">
                {props.linktext}
              </Link>
            )}
          </span>
        </p>
      </div>
      {props.children}
    </div>
  );
};

DashCards.defaultProps = {
  link: "/",
};

const Dashboard = (props) => {
  const [taskStatus, setTaskStatus] = useState(localStorage.getItem("task-added-status"));
  const [punchInOut, setPunchInOut] = useState("loading");
  const [loading, setLoading] = useState(false);
  const [workHours, setWorkHours] = useState({});
  const [taskHours, setTaskHours] = useState({});
  const [punchInOutInfo, setPunchInOutInfo] = useState({});
  const [board, setBoard] = useState({});
  const [boradTicket, setBoardTicket] = useState({});
  const [latestAssignments, setLatestAssignments] = useState({});
  const [time, setTime] = useState("00:00:00 Hrs");
  const navigate = useNavigate();
  var punchInOutStatus = "";
  // const punchInDateTime = moment().format("DD-MM-YYYY hh:mm:ss");
  //setTime(upTime());
  const uId = localStorage.getItem("userId");
  const { loginData, updateData } = useContext(LoginManageContext);
  var stateInfo = loginData;
  const [show, setShow] = useState(false);
  const [calender, showCalender] = useState(false);
  const [compareWorkHours, setCompareWorkHours] = useState(false);

  const modalOpen = () => {
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

const modalCompareWorkHoursOpen = () => {
    setCompareWorkHours(true);
  };

  const modalCompareWorkHoursClose = () => {
    setCompareWorkHours(false);
  };
  /*
   * time punch in-out
   */
  const upTime = () => {
    let d = new Date();

    let fulltime = d.toLocaleTimeString();

    // alert(fulltime);
    return fulltime;
  };
  const timeStart = () => {
    setInterval(() => {
      setTime(upTime());
    }, 1000);
  };
  /*
   * to use punch in
   */
  const punchIn = async () => {
    // Make a request for a user with a given ID
    //if(punchInOutInfo.a_id)
    setLoading(true);
    axios
      .post("/punchInOutApi.php", { u_id: uId, punchInOutStatus: "IN" })
      .then(function (response) {
        const res = response.data;

        if (res.statusCode === 200) {
          setPunchInOutInfo({
            ...punchInOutInfo,
            a_id: res.result.id,
            u_id: uId,
            punchInOutStatus: "OUT",
            punchInDateTime: res.result.a_punchin,
          });
          setPunchInOut("OUT");
          setLoading(false);
        } else {
          alert("Oops something went wrong");
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };
  /*
   * to use punch out
   */
  const punchOut = async () => {
    setLoading(true);
    const taskAddStatus = localStorage.getItem("task-added-status");
    setTaskStatus(taskAddStatus);
    if (taskAddStatus === "true") {
      var text = "Are you sure want to Punch Out ?";
      if (window.confirm(text) === true) {
        axios
          .post("/taskHourCompareApi.php", {
            u_id: uId,
            punchInDateTime: punchInOutInfo.punchInDateTime,
            punchInOutStatus: "OUT",
          })
          .then(function (response) {
            const res = response.data;
            if (res.statusCode === 200) {
              if(res.result < 0 || res.result > 2) {
              //console.log(res.result);
              setWorkHours(res.workHours);
              setTaskHours(res.taskHours);
              modalCompareWorkHoursOpen();
            } else {
              setLoading(true);
              confirmedPunchOut();
            }
            } else {
            setLoading(true);
            confirmedPunchOut();
          }
          })
          .catch(function (error) {
            console.log(error);
          });
          setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      alert(" Please add the tasks for today before Punch Out. ");
      navigate("/manage-tasks");
      // return <TaskMgmt />
    }
  };

  const correctTaskHours = () => {
    navigate("/manage-tasks",{state: { showTasks: true }});
  };

  const comparedPunchOut = () => {
    setLoading(true);
    confirmedPunchOut();
    modalCompareWorkHoursClose();
  };

  const confirmedPunchOut = () => {
    axios
    .post("/punchInOutApi.php", {
      a_id: punchInOutInfo.a_id,
      u_id: uId,
      punchInDateTime: punchInOutInfo.punchInDateTime,
      punchInOutStatus: "OUT",
    })
    .then(function (response) {
      const res = response.data;
      if (res.statusCode === 200) {
        setPunchInOut("IN");
        localStorage.setItem("task-added-status", "false");
        setTaskStatus(false);
      } else {
        console.log("Oops something went wrong");
      }
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      console.log(error);
    });
  };

  // to get punch in-out current status
  const getPunchInOutInfo = () => {
    // Make a request for a user to get punch in-out info
    axios
      .post("/getPunchInOutApi.php", {
        a_id: punchInOutInfo.a_id,
        u_id: stateInfo.userData.id,
      })
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          if (res.result.current_punch === "IN") {
            punchInOutStatus = "OUT";
          } else {
            punchInOutStatus = "IN";
          }

          setPunchInOutInfo({
            ...punchInOutInfo,
            a_id: res.result.id,
            u_id: uId,
            punchInOutStatus: punchInOutStatus,
            punchInDateTime: res.result.a_punchin,
          });
          setPunchInOut(punchInOutStatus);
        } else {
          console.log("Oops something went wrong");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAllBoard = () => {
    axios
      .get(
        "https://api.trello.com/1/members/" +
          stateInfo.userData.trello_user_name +
          "/boards?key=" +
          stateInfo.userData.trello_user_key +
          "&token=" +
          stateInfo.userData.trello_token +
          ""
      )
      .then(function (response) {
        if (response.status === 200) {
          setBoard(response.data);
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };
  const getAllBoardTicket = () => {
    axios
      .get(
        "https://api.trello.com/1/search?key=" +
          stateInfo.userData.trello_user_key +
          "&token=" +
          stateInfo.userData.trello_token +
          "&query=is:open%20member:" +
          stateInfo.userData.trello_user_name +
          ""
      )
      .then(function (response) {
        if (response.status === 200) {
          setBoardTicket(response.data);
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };
  const getLatestAssignments = () => {
    axios
      .post("/getUserTodayTaskApi.php", {
        trelloId: stateInfo.userData.trello_user_id,
        key: stateInfo.userData.trello_user_key,
        token: stateInfo.userData.trello_token,
      })
      .then(function (response) {
        const res = response.data;
        if (res.statusCode === 200) {
          setLatestAssignments(res.result);
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };
  // get all task that done by today
  const getAllTaskDoneByToday = () => {
    setLoading(true);
    axios
      .post("/getAllTaskDoneByTodayApi.php", {
        uId: uId,
      })
      .then(function (response) {
        const res = response.data;
        if (response.status === 200 && res.result !== null) {
          if (res.result.length > 0) {
            localStorage.setItem("task-added-status", "true");
            setTaskStatus(true);
          } else {
            localStorage.setItem("task-added-status", "false");
            setTaskStatus(false);
          }
          setLoading(false);
          //setDisplayTodayWork(res.result);
        } else {
          setLoading(false);
          console.log("Oops someting went wrong");
        }
      });
  };
  //update dashboard on page load
  const getUpdateDashboard = () => {
    axios
      .post("/updateDashboardApi.php", {
        uId: uId,
      })
      .then(function (response) {
        const res = response.data;
        //console.log("response.status", res.result.length);
        if (response.status === 200) {
          updateData(res.result);
          localStorage.setItem("userinfo", JSON.stringify(res.result));
        } else {
          console.log("Oops someting went wrong");
        }
      });
  };
  const showCalenderModal = () => {
    showCalender(true);
  };
  const hideCalender = () => {
    showCalender(false);
  };
  useEffect(() => {
    timeStart();
    getPunchInOutInfo();
    getAllBoard();
    getAllBoardTicket();
    getLatestAssignments();
    getAllTaskDoneByToday();
    getUpdateDashboard();
  }, []);

  useEffect(() => {
    getAllTaskDoneByToday();
  }, [taskStatus]);

  // Save the punch status to localStorage whenever it changes to "IN" or "OUT".
  useEffect(() => {
    if (punchInOut === "IN" || punchInOut === "OUT") {
      localStorage.setItem("punchInOut", punchInOut);
    }
  }, [punchInOut]);

  // Load the punch status from localStorage when the component mounts.
  useEffect(() => {
    const savedPunchStatus = localStorage.getItem("punchInOut");
    if (savedPunchStatus) {
      setPunchInOut(savedPunchStatus);
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={modalClose}
        dialogClassName="lv-status-task"
        aria-labelledby="Leave Status"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <div className="modal_title_wrapper">
              <img src={onLeave} />
              <div className="quote_sec">
                <h3>Out of Office</h3>
                <p>Your presence is being missed.</p>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <hr className="hr_line" />
        <Modal.Body>
          <div className="leave_calender">
            <button onClick={showCalenderModal}>
              <BsFillCalendarCheckFill />
            </button>
          </div>
          <Container>
            <Row>
              <div>
                <ul className="leave-list">
                  {stateInfo.todaysLeaveAll?.length > 0 ? (
                    stateInfo.todaysLeaveAll?.map((row, index) => (
                      <li>
                        {/* {console.log(row)} */}
                        <div className="emp-details">
                          <img
                            className="emplv"
                            alt={row.first_name}
                            src={
                              row.image !== "" && row.image !== null
                                ? "https://crm.nablasol.net/fromAPI/" +
                                  row.image
                                : row.gender === "Male"
                                ? "https://crm.nablasol.net/fromAPI/male.png"
                                : "https://crm.nablasol.net/fromAPI/female.png"
                            }
                          />
                          <p>
                            {row.first_name}
                            <span className="lvemp-designation">
                              {row.designation}
                            </span>
                          </p>
                        </div>
                        <span className="leave-type">
                          {row.leave_type_c == "full day" ? "Full Day" : ""}{" "}
                          {row.leave_type_c == "second half" ? "2nd Half" : ""}{" "}
                          {row.leave_type_c == "first half" ? "1st Half" : ""}
                        </span>
                      </li>
                    ))
                  ) : (
                    <div className="norecords-display">
                      No Employee On Leave Today!
                    </div>
                  )}
                </ul>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* Leave Calender */}
      <Modal
        show={calender}
        onHide={hideCalender}
        dialogClassName="leave-calendar"
        aria-labelledby="Leave Calendar"
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Calendar />
        </Modal.Body>
      </Modal>
      {/* Leave calender */}
      {/* Start - Modal for task hour and work hour comparison at the time of Punch out */}
      <Modal
        show={compareWorkHours}
        onHide={modalCompareWorkHoursClose}
        dialogClassName="lv-status-task"
        aria-labelledby="Task hour and work hour comparison"
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            <div className="modal_title_wrapper">
            <img className="compare_img" src={Issue} size="sm"/>
              <div className="compare_sec">
                <h3>Task hour and work hour comparison</h3>
                <hr className="hr_line" />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <div>
                <p className="dangerAlert">There is a <b>mismatch</b> in your Trello task hours and your working hours.
                <Tooltip title="The maximum difference between total working hours and total Trello tasks hours is 2 hours. Also, total Trello tasks hours shouldn't exeed total working hours.">
                  <span
                  className="mismatch-hint"

                >
                  <RiInformationLine />
                </span>
                </Tooltip>
                </p>
                <div id="records" class="main-view">
                  <div className="view-grid grid-dashlet">
                    <div class="records-wrapper">
                      <table>
                        <thead>
                          <tr>
                            <th> Total Working Hours Till Now </th>
                            <th> Total Trello Tasks Hours </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{workHours} Hrs</td>
                            <td>{taskHours} Hrs</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <button
                type="button"
                onClick={comparedPunchOut}
                className="modal-btn">
                  Punch Out Anyway
                </button>
                <button
                type="button"
                className="modal-btn"
                onClick={correctTaskHours}>
                  Correct It
                </button>
              </div>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      {/* End - Modal for task hour and work hour comparison at the time of Punch out */}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id={props.name} className="main-view">
            <div className="view-head">
              <h6> DASHBOARD </h6>
              <form action="#" method="POST">
                <span
                  className="punch-note"
                  title="Contact HR in case of doubt"
                >
                  <RiInformationLine />
                </span>
                {/* <button
                  type="button"
                  style={{
                    display: punchInOut == "loading" ? "block" : "none",
                  }}
                  onClick={punchIn}
                  className="punch-btn"
                  disabled
                >
                  Loading ...
                </button> */}
                <button
                  type="button"
                  style={{ display: punchInOut == "IN" ? "block" : "none" }}
                  onClick={punchIn}
                  className="punch-btn"
                  disabled={loading}
                >
                  <span className="punch-clock">
                    <RiTimerLine />
                  </span>
                  {loading? "Loading..." : "Punch In"}
                </button>
                <button
                  type="button"
                  style={{ display: punchInOut == "OUT" ? "block" : "none" }}
                  onClick={punchOut}
                  className="punch-btn"
                  disabled={loading}
                >
                  <span className="punch-clock">
                    <RiTimerLine />
                  </span>
                  {loading? "Loading..." : "Punch Out"}
                </button>
                <input
                  readOnly
                  value={time}
                  className="form-control punch-hrs"
                />
              </form>
            </div>
            <div className="view-grid">
              {/* <Tmeet /> */}
              {/* <Punch /> */}
              {/* <BDay /> */}
              {/* <Dash_holiday /> */}
              {/* <Leave /> */}

              <Greetings latestAssignments={latestAssignments} />

              <DashCards label="bday" name="B'Day Buddies">
                <BDay
                  latestBirthday={stateInfo.latestBirthday}
                  getDateFormate={getDateFormate}
                />
              </DashCards>

              <DashCards
                label="lvstatus"
                name="Out of Office"
                modalOpen={modalOpen}
                linktext="View All"
                // onClick={modalOpen}
              >
                {/* {console.log(stateInfo.todaysLeave)} */}
                <Tmeet todaysLeave={stateInfo.todaysLeave} />
              </DashCards>

              <DashCards
                label="hday"
                name="Upcoming Holidays"
                link="/holiday"
                state={stateInfo}
                linktext="View All"
                modalOpen=""
              >
                <DashHoliday
                  latestHoliday={stateInfo.latestHoliday}
                  getDateFormate={getDateFormate}
                />
              </DashCards>

              <DashCards
                label="leave"
                name="Leave Report"
                link="/leave-policy"
                linktext="View All"
                modalOpen=""
              >
                <Leave leaveInfoUser={stateInfo.leaveInfoUser} />
              </DashCards>

              <DashCards label="trello" name="Latest Assignments">
                <Trello latestAssignments={latestAssignments} />
              </DashCards>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
export { DashCards };
