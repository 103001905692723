import React, { useContext, useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useTable } from "react-table";
import axios from "axios";
import { LoginManageContext } from "./../App";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { TASKS } from "./Columns";
import { BsBackspaceFill } from 'react-icons/bs';
import { useTeamMemberContext } from "../TeamMembersContext";

const CustomTabContent = ({ Tasks = [{}] }) => {
    const columns = useMemo(() => TASKS, []);
    const data = useMemo(() => Tasks, [Tasks]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns: columns,
            data: data,
        });

    const truncate = (string, n) => {
        return string?.length > n ? string.substr(0, n - 1) + "..." : string;
    };
    return (
        <div className="table-responsive">
            <table {...getTableProps()} className="panel_table table">
                <thead>
                    {headerGroups.map((headerGroup) => {
                        return (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="panel_table_row scaffolding"
                            >
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className="panel_table_heading"
                                        style={{ width: " 20%" }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        );
                    })}
                </thead>
                {data.length > 0 ? (
                    <tbody {...getTableBodyProps()} className="panel_table_tbody">
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="panel_table_row">
                                    {row.cells.map((cell) => {
                                        switch (cell.column.Header) {
                                            case "Description":
                                                return (
                                                    <td className="panel_table_data">
                                                        <span>{truncate(cell.value, 60)}</span>
                                                    </td>
                                                );
                                            case "Task":
                                                return (
                                                    <td className="panel_table_data">
                                                        {truncate(cell.value, 20)}
                                                    </td>
                                                );
                                            default:
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="panel_table_data"
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                );
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <h3 className="noData">No Cards Available</h3>
                )}
            </table>
        </div>
    );
};
const Clients = () => {
    const location = useLocation();
    const { teamMemberId: userId } = useTeamMemberContext();
    const [Clients, setClients] = useState({});
    const [Tasks, setTasks] = useState([]);
    const [ClientTasks, setClientTasks] = useState({});
    const { loginData } = useContext(LoginManageContext);
    const [loader, setLoader] = useState(false);
    const [mainloader, setMainLoader] = useState(true);
    const [defaultKey, setDefaultKey] = useState();
    const [defaultTaskLoading, setDefaultTaskLoading] = useState(false);
    const [userTeam, setUserTeam] = useState([]);
    var [teamMemberTeam, setTeamMemberTeam] = useState([]);
    const userRole = loginData.userData.user_role_c;
    var stateInfo = loginData;

    const getLoginUserClientInfo = async () => {
        setMainLoader(true);
        const res = await axios.post("/getAllClientsApi.php", { uId: loginData.userData.id })
        const response = await res.data.result;
        setUserTeam(response);
        setDefaultTaskLoading(false);
    };

    const defaultClientTask = () => {
        setLoader(true);
        var clientID = defaultKey;
        if(loginData.userData.id == '9088b19c-bed5-e042-667e-60c730f49b3e' && userId == '9088b19c-bed5-e042-667e-60c730f49b3e') {
            axios
            .post("/getUserTodayTaskClientWiseApi.php", {
                uId: userId,
                clientId: clientID,
            })
            .then((res) => {
                const response = res.data;
                setTasks(response);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            if (typeof ClientTasks[clientID] !== 'undefined') {
                setTasks(ClientTasks[clientID]);
                setLoader(false);
            }
        }

    };

    const getUserClientInfo = async () => {
        setMainLoader(true);
        if(loginData.userData.id == '9088b19c-bed5-e042-667e-60c730f49b3e' && userId == '9088b19c-bed5-e042-667e-60c730f49b3e') {
            setTimeout(() => {
                setClients(userTeam);
                setDefaultKey(userTeam?.[0]?.id);
                setTeamMemberTeam(userTeam);
                setMainLoader(false);
                setDefaultTaskLoading(true);
            }, 500);
        } else {
            const res = await axios.post("/getAllProcessTasksClientsApi.php", {
                uId: userId,
            });
            const response = await res.data.result;
            const responseCT = await res.data.clienttasks;

            setClients(response);
            setDefaultKey(response?.[0]?.id);
            setTeamMemberTeam(response);
            setClientTasks(responseCT);
            setMainLoader(false);
            setDefaultTaskLoading(true);
        }

    };

    const getClientTasks = (e) => {
        setLoader(true);
        var clientID = e;
        if(loginData.userData.id == '9088b19c-bed5-e042-667e-60c730f49b3e' && userId == '9088b19c-bed5-e042-667e-60c730f49b3e') {
            axios
            .post("/getUserTodayTaskClientWiseApi.php", {
                uId: userId,
                clientId: clientID,
            })
            .then((res) => {
                const response = res.data;
                setTasks(response);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
            });
        } else {
            setTasks(ClientTasks[clientID]);
            setLoader(false);
        }

    };

    const defaultTaskFn = () => {
        getUserClientInfo();
        defaultClientTask();
    }
    const result = userTeam.length > 0 && teamMemberTeam.length > 0 ? userTeam.filter(o1 => teamMemberTeam.some(o2 => o1.id === o2.id)) : [];

    useEffect(() => {
        getLoginUserClientInfo();
    }, []);

    useEffect(() => {
        defaultTaskFn();
    }, [defaultTaskLoading]);

    return (
        <>
            <div className="main">
                <Sidebar />
                <div
                    id="primary"
                    style={{ backgroundImage: "url('/img/hrm-bg-img.jpg')" }}
                >
                    <Header />
                    <div id="teams" className="main-view">
                        <div className="view-head">
                            <h6> Team Members </h6>
                            <NavLink
                                to="/team-members"
                                state={{ userInfo: stateInfo.userData }}
                                activeClassName="hello"
                            >
                                {" "}
                                <span className="view_back_btn">Back <BsBackspaceFill /></span>
                            </NavLink>
                        </div>
                        <div class="team-wrapper">
                            <div class="team-grid client_view_sec">
                                <h3 className="client_view_heading">{location.state?.userInfo}</h3>
                                <div className="panel_tabs">
                                    {userRole == "TL" || userRole === "PM"
                                        || userRole === "PC" || userRole === "CEO" ||
                                        result.length > 0 ? (
                                        Clients && Clients.length > 0 ? (
                                            <Tabs
                                                defaultActiveKey={defaultKey}
                                                id="clientTab"
                                                className="mb-3"
                                                onSelect={(e) => getClientTasks(e)}
                                            >
                                                <Tab title={"Clients"} className="first_tab" />
                                                {Clients?.length > 0 ? (
                                                    Clients.map((client) => {
                                                        return (
                                                            <Tab eventKey={client.id} title={client.client_c}>
                                                                {loader === true ? (
                                                                    <div className="loader">
                                                                        <img src="/img/hrm-loader.svg" alt="loader" />
                                                                    </div>
                                                                ) : (
                                                                    <CustomTabContent Tasks={Tasks.result} />
                                                                )}
                                                            </Tab>
                                                        );
                                                    })
                                                ) : (
                                                    <div>No Clients Found</div>
                                                )}
                                            </Tabs>
                                        ) : (
                                            <>
                                                {mainloader === true ? (
                                                    <div className="loader">
                                                        <img src="/img/hrm-loader.svg" alt="loader" />
                                                    </div>
                                                ) : (
                                                    <div>No Clients Found</div>
                                                )}
                                            </>
                                        )): (
                                            <>
                                                                {mainloader === true ? (
                                                                    <div className="loader">
                                                                        <img src="/img/hrm-loader.svg" alt="loader" />
                                                                    </div>
                                                                ) : (
                                                                    <div>No Clients Found</div>
                                                                )}

                                                </>

                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Clients;
