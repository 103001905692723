import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import "react-multiple-select-dropdown-lite/dist/index.css";
import { NavLink, useLocation, useParams } from "react-router-dom";

import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import FormControl from "@mui/material/FormControl";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsBackspaceFill } from 'react-icons/bs';
import { useTeamMemberContext } from "../TeamMembersContext";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

function TrelloTasks() {
  const [mainloader, setMainLoader] = useState(true);
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("100%");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(true);
  const [viewColumnBtn, setViewColumnBtn] = useState(true);
  const [filterBtn, setFilterBtn] = useState(true);
  const loginUid = localStorage.getItem("userId");
  let i = 0;

  const columns = [
    { name: "Board", options: { filterOptions: { fullWidth: true } } },
    "List",
    "Task",
    {
      name: "Description",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "500px", maxWidth: "500px", overflow: "hidden" } }),
        customBodyRender: function (value, tableMeta, updateValue) { i = i + 1; return (<><div id={i} >{value.substring(0, 100) + " ..."}</div><ReactTooltip style={{ width: 600 }} anchorId={i} place="bottom" content={value} /></>) },
      },
    },
    "Labels"
  ];

  const options = {
    sortOrder: {
      name: 'Board',
      direction: 'asc'
    },

    search: searchBtn,
    download: downloadBtn,
    downloadOptions: {
      filename: 'TrelloTasksEmployee.csv',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true | false,
        useDisplayedRowsOnly: true | true
      },
    },
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,

  };

  const location = useLocation();
  //const { userId } = useParams();
  const { teamMemberId: userId } = useTeamMemberContext();
  const [data, setData] = useState([]);
  const getTrelloTasksReportInfo = () => {
    setMainLoader(true);
    axios
      .post("/getAllTrelloTaskUser.php", {
        uId: userId,
        loginUid: loginUid
      })
      .then((res) => {
        const response = res.data.result;
        let my_array = [];
        response.forEach(obj => {
          let inside_array = [];
          Object.entries(obj).forEach(([key, value]) => {
            inside_array.push(`${value}`);
          });
          my_array.push(inside_array);
        });

        setData(my_array);
        setMainLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTrelloTasksReportInfo();
  }, []);
  return (
    <>
      {" "}
      <div className="main">
        <Sidebar />
        <div id="primary">
          <Header />
          <div id="reports" className="main-view">
            <div className="view-head">
              <h6> Assigned Tasks For Employee</h6>
              <NavLink
                to="/trello-tasks-employee"
                activeClassName="hello"
              >
                {" "}
                <span className="view_back_btn">Back <BsBackspaceFill /></span>
              </NavLink>
            </div>

            <div className="view-grid grid-dashlet">
              <div class="reports-wrapper">
                <h3 className="client_view_heading">{location.state?.userInfo}</h3>
                {mainloader === true ? (
                  <div className="loader">
                    <img src="/img/hrm-loader.svg" alt="loader" />
                  </div>
                ) : (
                  <CacheProvider value={muiCache}>
                    <ThemeProvider theme={createTheme()}>
                      <FormControl />
                      <MUIDataTable
                        title={"Assigned Tasks For Employee - " + location.state?.userInfo}
                        data={data}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  </CacheProvider>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrelloTasks;